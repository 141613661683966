import { OpenAI } from "openai";

const callOpenAI = async (prompt) => {
  try {
    const client = new OpenAI({
      apiKey: process.env.REACT_APP_OPENAI_API_KEY,
      dangerouslyAllowBrowser: true
    });

    const thread = await client.beta.threads.create();

    await client.beta.threads.messages.create(
      thread.id,
      {
        role: "user",
        content: prompt
      }
    );

    const run = await client.beta.threads.runs.create(
      thread.id,
      { 
        assistant_id: "asst_aTyE7bLCFF10NhouIL00LQ4X"
      }
    );

    while (true) {
      const runStatus = await client.beta.threads.runs.retrieve(
        thread.id,
        run.id
      );

      const messages = await client.beta.threads.messages.list(thread.id);
      const latestMessage = messages.data[0];

      if (runStatus.status === 'completed') {
        const messageContent = latestMessage.content[0].text.value.replace(/【[^】]*】/g, '');
        return messageContent;
      }

      if (runStatus.status === 'failed') {
        throw new Error('Assistant run failed');
      }

      await new Promise(resolve => setTimeout(resolve, 1000));
    }
    
  } catch (err) {
    return `Error: ${err.message}`;
  }
};

export default callOpenAI;
