import React, { useState } from 'react';
import callOpenAI from '../OpenAPI/OpenAPI';
import './ChatBot.css';

const ChatBot = () => {
  
  const keywords = [
    "Chatbot utveckling",
    "AI chatbot företag",
    "Kundservice automation",
    "Företagschatbot",
    "Digital kundtjänst",
    "Chatbot integration",
    "Skräddarsydd chatbot",
    "Intelligent chatbot",
    "Chatbot lösningar",
    "Automatiserad kundservice"
  ];

  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "Service",
    "name": "Chatbot byggare",
    "description": "Vi hjälper dig att bygga en Chatbot för ditt företag.",
    "provider": {
      "@type": "Organization",
      "name": "techlära",
      "url": "https://techlara.se"
    },
    "serviceType": "Chatbot byggare",
    "areaServed": "Sverige",
    "keywords": keywords
  };

  const [prompt, setPrompt] = useState('');
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState('');

  const handleSubmit = ({ prompt }) => {
    setPrompt(prompt);
    setLoading(true);
    setResponse('');
    callOpenAI(prompt).then(response => {
      setResponse(response);
      setLoading(false);
    });
  }

  return(
    <div className="chat-container">
      <button className='btn-green question-button' onClick={() => handleSubmit({ prompt: "Vad kostar det att laga en punka?" })}>Vad kostar det att laga en punka?</button>
      <button className='btn-green question-button' onClick={() => handleSubmit({ prompt: "Har ni dropin?" })}>Har ni dropin?</button><br />
      <input className='input-text' type="text" placeholder="Ställ en fråga om Cykel & Natur" onChange={(e) => setPrompt(e.target.value)}/><br />
      <button className='btn-green question-button' onClick={() => handleSubmit({ prompt: prompt })}>Ställ fråga</button>
      {prompt && (
        <div>
          {loading === true && <p>Laddar {prompt} ...</p>}
          <p>
            {response}      
            <br /> 
          </p>
        </div>
      )}
    </div>
  );
}

export default ChatBot;